import { Outlet, useLocation } from "react-router-dom";
import BackButton from "../components/Buttons/BackButton";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

export default function BackLayout() {
  const { bots } = useSelector((state) => state.bots);
  const [renderBackButton, setRenderBackButton] = useState(true);
  const location = useLocation();
  const endOfPathName = location.pathname.split('/').pop();

  useEffect(() => {
    setRenderBackButton(!(bots.length === 1 && endOfPathName === bots[0].name));
  }, [location])
  return (
    <div>
      {renderBackButton && <BackButton />}
      <Outlet />
    </div>
  );
}
