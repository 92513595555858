import { useState } from "react";
import Button from "./Button";
import Preview from "../Preview/Preview";
import { useTranslation } from "react-i18next";

export default function PreviewButton({ main, sub, icon }) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Button
        variant="secondary"
        type="submit"
        onClick={() => {
          setOpen(true);
        }}
      >
        {t("layout.Preview")}
      </Button>
      {open && (
        <Preview
          main={main}
          sub={sub}
          icon={icon}
          onClose={() => {
            setOpen(false);
          }}
        />
      )}
    </>
  );
}
