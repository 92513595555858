import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { Icon } from "@dnb/eufemia";

export default function FeatureCard({ title, description, link, icon }) {
  return (
    <FeatureCardWrapper to={link}>
      <Icon icon={icon} size={"medium"} />
      <Title>{title}</Title>
      <Description dangerouslySetInnerHTML={{ __html: description }} />
    </FeatureCardWrapper>
  );
}

export function NewFeatureCard({ description, icon, link }) {
  return (
    <FeatureCardWrapper
      to={link}
      style={{ justifyContent: "center", gap: "2rem" }}
    >
      <Icon icon={icon} size={"large"} />
      <Description>{description}</Description>
    </FeatureCardWrapper>
  );
}

const FeatureCardWrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  background-color: #333333;
  border-radius: 0.5rem;

  border: 1px solid ${(props) => props.theme.borderColor};

  padding: 2rem;

  width: 240px;
  height: 240px;
  text-decoration: none;

  color: ${(props) => props.theme.cardTextColor};

  &:hover {
    background-color: ${(props) => props.theme.hoverColor};
    color: ${(props) => props.theme.hoverTextColor};
    border: 1px solid ${(props) => props.theme.hoverBorderColor};
  }
`;

const Title = styled.div`
  font-size: var(--font-size-large);
  font-weight: 700;
  word-wrap: break-word;
  text-align: center;
`;

const Description = styled.div`
  text-align: center;

  a {
    color: ${(props) => props.theme.cardTextColor} !important;
    z-index: 3000;
  }
`;
