import { useTranslation } from "react-i18next";
import Button from "./Button";

export default function AddButton({ disabled = false, editMode = false}) {
  const { t } = useTranslation();

  return (
    <Button
      variant="secondary"
      type="submit"
      disabled={disabled}
    >
      {editMode ? "Edit" :  t("layout.Add")}
    </Button>
  );
}
