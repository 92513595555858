import { createSlice } from "@reduxjs/toolkit";

export const botsSlice = createSlice({
    name: "bots",
    initialState: {
      bots: []
    },
    reducers: {
      setBots: (state, action) => {
        state.bots = action.payload;
      },
    },
  });
  
  export const { setBots } = botsSlice.actions;
  export default botsSlice.reducer;