import { useTranslation } from "react-i18next";
import useHandleSave from "../../hooks/useHandleSave";
import Button from "./Button";

export default function SaveButton({ disabled = false}) {
  const handleSave = useHandleSave();
  const { t } = useTranslation();

  return (
    <Button
      variant="secondary"
      type="submit"
      onClick={() => handleSave()}
      disabled={disabled}
    >
      {t("layout.Save")}
    </Button>
  );
}
