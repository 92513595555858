import { Icon } from "@dnb/eufemia";
import { chevron_down } from "@dnb/eufemia/icons";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";

export default function DropdownMenu({ onChange, data, selectedItem }) {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleOutsideClick(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  function handleItemClick(item) {
    onChange(item);
    setIsOpen(false);
  }

  return (
    <DropdownWrapper ref={wrapperRef}>
      <DropdownButton onClick={() => setIsOpen(!isOpen)}>
        <DropdownButtonText>
        {selectedItem
          ? <>
            <DropdownButtonTitle>{selectedItem.content[0]}</DropdownButtonTitle>
            {selectedItem.content[1]}
          </> : 
            <DropdownButtonTitle>Filter</DropdownButtonTitle>
        }
        </DropdownButtonText>
        <Icon icon={chevron_down}/>
      </DropdownButton>
      {isOpen && (
        <DropdownList>
          {data.map((item) => (
            <DropdownItem
              key={item.content[1]}
              onClick={() => handleItemClick(item)}
            >
              <ItemTitle>{item.content[0]}</ItemTitle>
              {item.content[1]}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownWrapper>
  );
}

const DropdownWrapper = styled.div`
  position: relative;
  width: 20rem;
`;

const DropdownButton = styled.button`
  width: 100%;
  border: 1px solid white;
  border-radius: 8px;
  padding: 10px;

  background-color: #313131;
  color: white;

  text-overflow: ellipsis;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  text-align: left;

  &:hover {
    background-color: ${(props) => props.theme.hoverColor};
    color: ${(props) => props.theme.hoverTextColor};
    border: 1px solid ${(props) => props.theme.hoverBorderColor};
  }
`;

const DropdownButtonText = styled.div `
  width: 80%;
`;

const DropdownButtonTitle = styled.div `
  font-weight: 500;
  font-size: 18px;
`;

const appear = keyframes`
  0% { opacity: 0; transform: translateY(-20px); }
  100% { opacity: 1; transform: translateY(0); }
`;

const DropdownList = styled.div`
  position: absolute;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1;
  animation: ${appear} 0.2s ease-in-out forwards;
`;

const DropdownItem = styled.div`
  padding: 10px;
  cursor: pointer;

  width: 100%;
  min-height: 5.625rem;
  border: 1px solid white;
  border-radius: 8px;
  padding: 20px;

  background-color: #313131;
  color: white;

  display: flex;
  flex-direction: column;

  &:hover {
    background-color: ${(props) => props.theme.hoverColor};
    color: ${(props) => props.theme.hoverTextColor};
    border: 1px solid ${(props) => props.theme.hoverBorderColor};
  }

`;

const ItemTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
`;
