import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "../Dialogs/Dialog";
import { pin_medium } from "@dnb/eufemia/icons";
import useHandleSave from "../../hooks/useHandleSave";
import { setChanges } from "../../features/changeSlice";
import { toggleQueriesLang } from "../../features/langSlice";
import { useTranslation } from "react-i18next";
import Switch from "./Switch";
import { Div, P } from "@dnb/eufemia";
import styled from "@emotion/styled";

export default function QueriesLangSwitch() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const lang = useSelector((state) => state.lang.queriesLang);

  const { t } = useTranslation();

  const changes = useSelector((state) => state.change.changes);
  const handleSave = useHandleSave();

  const [checked, setChecked] = useState(lang === "no" ? false : true);

  function handleOnChange() {
    if (changes === true) {
      setOpen(true);
    } else {
      setChecked(checked ? false : true);
      dispatch(toggleQueriesLang());
    }
  }

  return (
    <QueriesLangSwitchWrapper>
      <P> {t("langswitch.header")} </P>
      <br/>
      <Switch checked={checked} onChange={handleOnChange} />
      {open && (
        <Dialog
          title={t("dialogs.save.title")}
          icon={pin_medium}
          discardText={t("dialogs.save.discardText")}
          acceptText={t("dialogs.save.acceptText")}
          content={t("dialogs.save.content")}
          onClose={() => {
            handleSave();
            setOpen(false);
          }}
          onConfirm={() => {
            dispatch(setChanges(false));
            setChecked(checked ? false : true);
            dispatch(toggleQueriesLang());
            setOpen(false);
          }}
          onExit={() => setOpen(false)}
        />
      )}
    </QueriesLangSwitchWrapper>
  );
}


const QueriesLangSwitchWrapper = styled(Div)`
  text-align: center;
`