import styled from "@emotion/styled";
import { useDispatch } from "react-redux";
import {
  setMain,
  setSub,
  setWelcomeDisclaimer,
  setDisclaimerUrl,
} from "../../features/welcomeSlice.js";
import { Label, FormWrapper, StyledInput, InputWrapper } from "./Form";
import { useTranslation } from "react-i18next";
import { Div } from "@dnb/eufemia";

export default function WelcomeForm({
  main,
  sub,
  welcomeDisclaimer,
  disclaimerUrl,
}) {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  function handleMainChange(e) {
    dispatch(setMain(e.target.value));
  }

  function handleSubChange(e) {
    dispatch(setSub(e.target.value));
  }

  function handleWelcomeDisclaimerChange(e) {
    dispatch(setWelcomeDisclaimer(e.target.value));
  }

  function handleDisclaimerUrlChange(e) {
    dispatch(setDisclaimerUrl(e.target.value));
  }

  return (
    <FormWrapper>
      <InputWrapper>
        <Label>{t("welcomePage.MainHeader")}</Label>
        <StyledInput
          placeholder={t("common.ClickToAdd")}
          value={main}
          onChange={handleMainChange}
        />
      </InputWrapper>
      <InputWrapper>
        <Label>{t("welcomePage.SubHeader")}</Label>
        <StyledTextArea
          placeholder={t("common.ClickToAdd")}
          value={sub}
          onChange={handleSubChange}
          rows="2"
        />
      </InputWrapper>
      <DisclaimerWrapper>
        <InputWrapper>
          <Label>{t("welcomePage.DisclaimerText")}</Label>
          <StyledTextArea
            placeholder={t("common.ClickToAdd")}
            value={welcomeDisclaimer}
            rows={4}
            onChange={handleWelcomeDisclaimerChange}
          />
        </InputWrapper>
        <InputWrapper>
          <Label>{t("welcomePage.DisclaimerURL")}</Label>
          <StyledTextArea
            placeholder={t("common.ClickToAdd")}
            value={disclaimerUrl}
            rows={3}
            onChange={handleDisclaimerUrlChange}
          />
        </InputWrapper>
      </DisclaimerWrapper>
    </FormWrapper>
  );
}

const StyledTextArea = styled.textarea`
  background-color: #333333;
  border: 1px solid ${(props) => props.theme.color};
  color: #ffffff;
  border-radius: 0.5rem;

  margin-bottom: 0.625rem;

  min-width: 18.75rem;

  box-shadow: none;
  padding: var(--spacing-x-small);

  :focus {
    border-radius: 0.2rem;
  }
`;

const DisclaimerWrapper = styled(Div)`
  margin-top: 1rem;
`;
