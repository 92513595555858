import styled from "@emotion/styled";
import { pca } from "../..";
import { useTranslation } from "react-i18next";

export default function LogoutButton() {
  const { t } = useTranslation();

  function handleLogout() {
    sessionStorage.clear();
    pca.logoutRedirect();
  }

  return (
    <StyledButton onClick={handleLogout}>{t("layout.LogOff")}</StyledButton>
  );
}

const StyledButton = styled.button`
  border: 1px solid ${(props) => props.theme.color};
  border-radius: 0.25rem;
  padding: 3px 4px 3px 4px;

  :hover {
    box-shadow: 0 0 0 1px ${(props) => props.theme.color};
  }
`;
