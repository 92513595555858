import styled from "@emotion/styled";

export default function Switch({ checked, onChange }) {
  return (
    <StyledSwitch>
      <input type="checkbox" checked={checked} onChange={onChange} />
      <span className={`${!checked ? "active left" : ""}`}>NO</span>
      <span className={`${checked ? "active right" : ""}`}>EN</span>
    </StyledSwitch>
  );
}

const StyledSwitch = styled.label`
  border: 1px solid ${(props) => props.theme.color};
  border-radius: 0.25rem;
  color: ${(props) => props.theme.color};
  user-select: none;

  padding: 6px 0 6px 0;

  span {
    padding: 6px 5px 6px 5px;
  }

  & input {
    opacity: 0;
    width: 0;
    height: 0;
    display: none; 
  }

  :hover {
    cursor: pointer;
  }

  .active {
    font-weight: 900;
    width: 100%;
    background-color: ${(props) => props.theme.color};
    color: ${(props) => props.theme.backgroundColor};
  }

  .left {
    border-radius: 0.1rem 0 0 0.1rem;
    height: max-content;
    width: 100%;
  }

  .right {
    border-radius: 0 0.1rem 0.1rem 0;
    height: max-content;
    width: 100%;

  }
`;
