import { Icon } from "@dnb/eufemia";
import { chevron_down } from "@dnb/eufemia/icons";
import styled from "@emotion/styled";
import { useState } from "react";

export default function SettingsAccordion({ title, children }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    // Using a $ sign to prevent the "React does not recognize the `isOpen` prop on a DOM element." warning
    <AccordionWrapper $isOpen={isOpen}>
      <AccordionTitle onClick={() => setIsOpen(!isOpen)}>
        <StyledIcon $isOpen={isOpen} icon={chevron_down} size={"medium"} />
        {title}
      </AccordionTitle>
      <AccordionContent $isOpen={isOpen}>
        <AccordionContentInner>{children}</AccordionContentInner>
      </AccordionContent>
    </AccordionWrapper>
  );
}

const AccordionWrapper = styled.div`
  margin-bottom: 10px;

  color: ${(props) => (props.$isOpen ? props.color : "#FFFFFF")};
  background-color: ${(props) => (props.$isOpen ? "transparent" : "#333333")};
  transition:
    background-color 0.5s ease,
    color 0.5s ease;

  border: 1px solid ${(props) => props.theme.color};
  border-radius: 0.5rem;
  padding: 20px 16px 20px 16px;

  width: 100%;
`;

const AccordionTitle = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: var(--font-size-basis);
  gap: 8px;
`;

const StyledIcon = styled(Icon)`
  transform: ${(props) => (props.$isOpen ? "rotate(-180deg)" : "rotate(0deg)")};
  transition: transform 0.5s ease;
`;

const AccordionContentInner = styled.div`
  padding-top: 30px;
`;

const AccordionContent = styled.div`
  overflow-y: ${(props) => (props.$isOpen ? "visible" : "hidden")};
  max-height: ${(props) => (props.$isOpen ? "1000px" : "0")};

  transition: max-height 0.3s ease;
`;
