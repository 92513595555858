import { createSlice } from "@reduxjs/toolkit";

export const uisettingsSlice = createSlice({
  name: "uisettings",
  initialState: {
    infos: null,
    tileName: "",
    tileDescription: "",
    goToLink: "",
  },
  reducers: {
    setInfos: (state, action) => {
      state.infos = action.payload;

      state.tileName = action.payload["tileName"];
      state.tileDescription = action.payload["tileDescription"];
      state.goToLink = action.payload["goToLink"];
    },
    setTileName: (state, action) => {
      state.tileName = action.payload;
      state.infos["tileName"] = action.payload;
    },
    setTileDescription: (state, action) => {
      state.tileDescription = action.payload;
      state.infos["tileDescription"] = action.payload;
    },
    setGoToLink: (state, action) => {
      state.goToLink = action.payload;
      state.infos["goToLink"] = action.payload;
    },
    resetInfos: (state) => {
      state.infos = null;
      state.tileName = "";
      state.tileDescription = "";
      state.goToLink = "";
    },
  },
});

export const {
  setInfos,
  setTileName,
  setTileDescription,
  setGoToLink,
  resetInfos,
} = uisettingsSlice.actions;
export default uisettingsSlice.reducer;
