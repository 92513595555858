import styled from "@emotion/styled";

export default function Unauthenticate() {
  return (
    <UnauthenticateWrapper>
      <Title>woops.. doesn&#39t look like you have access. </Title>
      Please contact the team lead for the bot you want access to.
    </UnauthenticateWrapper>
  );
}

const UnauthenticateWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-x-large);

  margin-bottom: var(--spacing-x-large);
`;
