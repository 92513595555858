import AddButton from "../Buttons/AddButton";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addFilter, editFilter } from "../../features/filtersSlice";
import CloseButton from "../Buttons/CloseButton";
import {
  Label,
  FormWrapper,
  StyledInput,
  InputWrapper,
  ButtonWrapper,
} from "./Form";
import { useTranslation } from "react-i18next";

export default function FilterForm({ onClick, parentId, id, initialData }) {
  const [name, setName] = useState(initialData.name);
  const [tag, setTag] = useState(initialData.tag);
  const [nameChanged, setNameChanged] = useState(false);
  const [tagChanged, setTagChanged] = useState(false);


  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    setName(initialData.name);
    setTag(initialData.tag)
  }, [initialData])

  function handleSubmit(e) {
    e.preventDefault();

    const payload = {
      id: parentId,
      child: {
        id: id,
        key: name,
        value: tag,
      },
    };

    dispatch(
      initialData.name !== "" ? editFilter(payload) : addFilter(payload),
    );
    onClick();
  }

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <CloseButton handleClose={onClick} />
      <InputWrapper>
        <Label>{t("filterPage.FilterName")}</Label>
        <StyledInput
          placeholder={t("common.ClickToAdd")}
          value={name}
          required
          onChange={(e) => {
            setName(e.target.value);
            setNameChanged(initialData.name != e.target.value);
          }}
        />
      </InputWrapper>
      <InputWrapper>
        <Label>{t("filterPage.RoutingTag")}</Label>
        <StyledInput
          placeholder={t("common.ClickToAdd")}
          value={tag}
          required
          onChange={(e) => {
            setTag(e.target.value);
            setTagChanged(initialData.name != e.target.value);
          }}
        />
      </InputWrapper>
      <ButtonWrapper>
        <AddButton editMode={initialData.name && initialData.tag} disabled={!(nameChanged || tagChanged)}/>
      </ButtonWrapper>
    </FormWrapper>
  );
}
