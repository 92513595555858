import { Icon } from "@dnb/eufemia";
import { arrow_down } from "@dnb/eufemia/icons";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";

export default function LargeDropdownMenu({
  onChange,
  data,
  selectedItem,
  buttonTitle,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleOutsideClick(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  function handleItemClick(item) {
    onChange(item);
    setIsOpen(false);
  }

  return (
    <DropdownWrapper ref={wrapperRef}>
      <DropdownButton onClick={() => setIsOpen(!isOpen)}>
        <DropdownButtonText>
          {selectedItem ? (
            <>
              {selectedItem}
            </>
          ) : (
            <DropdownButtonTitle>{buttonTitle}</DropdownButtonTitle>
          )}
        </DropdownButtonText>
        <Icon icon={arrow_down} />
      </DropdownButton>
      {isOpen && (
        <DropdownList>
          {data.map((item) => (
            <DropdownItem
              key={item}
              onClick={() => handleItemClick(item)}
            >
              {item}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownWrapper>
  );
}

const DropdownWrapper = styled.div`
  position: relative;
  width: 20rem;
  font-size: var(--font-size-basis);

  width: 100%;
`;

const DropdownButton = styled.button`
  width: 100%;
  padding: 16px;

  background-color: #333333;
  border: 1px solid ${(props) => props.theme.color};
  color: #ffffff;
  border-radius: 0.5rem;

  text-overflow: ellipsis;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  text-align: left;
`;

const DropdownButtonText = styled.div`
  width: 80%;
`;

const DropdownButtonTitle = styled.div``;

const appear = keyframes`
  0% { opacity: 0; transform: translateY(-20px); }
  100% { opacity: 1; transform: translateY(0); }
`;

const DropdownList = styled.div`
  position: absolute;
  width: 100%;

  z-index: 999;
  animation: ${appear} 0.2s ease-in-out forwards;
`;

const DropdownItem = styled.div`
  padding: 10px;
  cursor: pointer;

  width: 100%;
  border: 1px solid white;
  border-radius: 8px;
  padding: 20px;

  background-color: #313131;
  color: white;

  display: flex;
  flex-direction: column;

  &:hover {
    background-color: ${(props) => props.theme.hoverColor};
    color: ${(props) => props.theme.hoverTextColor};
    border: 1px solid ${(props) => props.theme.hoverBorderColor};
  }
`;
