import { css } from "@emotion/react";
import styled from "@emotion/styled";

export default function Button({ children, variant = "primary", ...props }) {
  return (
    <StyledButton variant={variant} {...props}>
      {children}
    </StyledButton>
  );
}

const StyledButton = styled.button`
  background-color: #313131;
  color: #ffffff;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  border: 1px solid ${(props) => props.theme.borderColor};
  border-radius: 3.125rem;
  padding: 6px 27px 6px 27px;

  &:hover {
    background-color: #1e1e1e;
  }

  &:active {
    background-color: #00343e;
  }

  &:disabled {
    background-color: #cdcdcd;
    border: none;
    opacity: 50%;
  }

  ${(props) =>
    props.variant === "secondary" &&
    css`
      background-color: #ffffff;
      color: #313131;
      border: 1px solid #313131;

      &&:hover {
        background-color: #b3e4d8;
        border: 1px solid #b3e4d8;
      }

      &&:active {
        background-color: #ffffff;
        color: #00343e;
        border: 1px solid #00343e;
      }

      &&:disabled {
        background-color: #cdcdcd;
        border: none;
        opacity: 50%;
      }
    `}
`;
