/* eslint-disable no-unused-vars */

import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import api from "../apiConfig";
import HistoryButton from "../components/Buttons/HistoryButton";
import SaveButton from "../components/Buttons/SaveButton";
import Loader from "../components/Loaders/Loader";
import { cog } from "@dnb/eufemia/icons";
import { useTranslation } from "react-i18next";
import { resetInfos } from "../features/welcomeSlice";
import ChatBotCard from "../components/Cards/ChatModeCard";
import {
  setInfos,
  setInputCharacterLimits,
  setOutputCharacterLimits,
  setGptModel,
  setSelectedGptType,
  setPrompt,
  setAccessPolicy,
  setWelcomeMessage,
  setContext,
  setSensitiveInfo,
} from "../features/backendSettingsSlice.js";
import SettingsAccordion from "../components/Accordions/SettingsAccordion.jsx";
import {
  ButtonWrapper,
  CardWrapper,
  ColumnDiv,
  ColumsWrapper,
  ContentWrapper,
  Divider,
  PageWrapper,
  SaveButtonWrapper,
  StyledInput,
  StyledTextArea,
  TextDiv,
} from "../components/Forms/SettingsForm.jsx";
import { InputWrapper, Label } from "../components/Forms/Form.jsx";
import LargeDropdownMenu from "../components/Dropdowns/LargeDropdownMenu.jsx";

export default function BackendSettings() {
  const dispatch = useDispatch();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const {
    inputCharacterLimit,
    outputCharacterLimit,
    gptModel,
    gptTypes,
    selectedGptType,
    prompt,
    welcomeMessage,
    accessPolicy,
    context,
    sensitiveInfo,
  } = useSelector((state) => state.backendsettings);

  const lang = useSelector((state) => state.lang.queriesLang);
  const changes = useSelector((state) => state.change.changes);

  function handleInputCharacterLimitsChange(e) {
    dispatch(setInputCharacterLimits(e.target.value));
  }

  function handleOutputCharacterLimitsChange(e) {
    dispatch(setOutputCharacterLimits(e.target.value));
  }

  function handleGptModelChange(e) {
    dispatch(setGptModel(e.target.value));
  }

  function handleGptTypeChange(item) {
    dispatch(setSelectedGptType(item));
  }

  function handlePromptChange(e) {
    dispatch(setPrompt(e.target.value));
  }

  function handleWelcomeMessageChange(e) {
    dispatch(setWelcomeMessage(e.target.value));
  }

  function handleAccessPolicyChange(e) {
    dispatch(setAccessPolicy(e.target.value));
  }

  useEffect(
    () => (document.title = `${params.tile.toUpperCase()}:  Backend Settings`),
    [],
  );

  useEffect(() => {
    setIsLoading(true);

    api
      .get("/appconfig", {params: {
        key: params.tile.toUpperCase()
      }})
      .then((res) => {
        dispatch(setInfos(res.data))
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Unable to load backend settings");
        console.error(err);
        setIsLoading(false);
      });

    return () => {
      dispatch(resetInfos());
    };
  }, [params.bot, dispatch, lang]);

  return !isLoading ? (
    <PageWrapper>
      <ContentWrapper>
        <ColumsWrapper>
          <ColumnDiv>
            <TextDiv>
              <SettingsAccordion
                title={t("backendSettingsPage.characterLimits")}
              >
                <InputWrapper>
                  <Label>{t("backendSettingsPage.outputCharacterLimit")}</Label>
                  <StyledInput
                    type="number"
                    placeholder={t("common.ClickToAdd")}
                    value={outputCharacterLimit}
                    onChange={handleOutputCharacterLimitsChange}
                  />
                </InputWrapper>
                <Spacing />
                <InputWrapper>
                  <Label>{t("backendSettingsPage.inputCharacterLimit")}</Label>
                  <StyledInput
                    type="number"
                    placeholder={t("common.ClickToAdd")}
                    value={inputCharacterLimit}
                    onChange={handleInputCharacterLimitsChange}
                  />
                </InputWrapper>
              </SettingsAccordion>
              <SettingsAccordion title={t("backendSettingsPage.gpt")}>
                <InputWrapper>
                  <Label>{t("backendSettingsPage.gptModel")}</Label>
                  <StyledInput
                    placeholder={t("common.ClickToAdd")}
                    value={gptModel}
                    onChange={handleGptModelChange}
                  />
                </InputWrapper>
                <Spacing />
                <InputWrapper>
                  <Label>{t("backendSettingsPage.gptType")}</Label>
                  {gptTypes && (
                    <LargeDropdownMenu
                      data={gptTypes}
                      selectedItem={selectedGptType}
                      onChange={handleGptTypeChange}
                      buttonTitle="Click for dropdown"
                    />
                  )}
                </InputWrapper>
              </SettingsAccordion>
              <ChatModeWrapper>
                <CardWrapper>
                  <ChatBotCard
                    icon={cog}
                    mode={t("backendSettingsPage.context")}
                    description={t("backendSettingsPage.description")}
                    checked={context ? "ON" : "OFF"}
                    onChange={(value) => {
                      dispatch(setContext(value === "ON" ? true : false));
                    }}
                  />
                  <ChatBotCard
                    icon={cog}
                    mode={t("backendSettingsPage.sensitiveInfo")}
                    description={t("backendSettingsPage.description")}
                    checked={sensitiveInfo ? "ON" : "OFF"}
                    onChange={(value) => {
                      dispatch(setSensitiveInfo(value === "ON" ? true : false));
                    }}
                  />
                </CardWrapper>
              </ChatModeWrapper>
            </TextDiv>
          </ColumnDiv>
          <Divider />
          <ColumnDiv>
            <TextDiv>
              <SettingsAccordion title={t("backendSettingsPage.prompts")}>
                <StyledTextArea
                  placeholder={t("common.ClickToAdd")}
                  value={prompt}
                  rows={5}
                  onChange={handlePromptChange}
                />
              </SettingsAccordion>
              <SettingsAccordion
                title={t("backendSettingsPage.welcomeMessage")}
              >
                <StyledTextArea
                  placeholder={t("common.ClickToAdd")}
                  value={welcomeMessage}
                  rows={5}
                  onChange={handleWelcomeMessageChange}
                />
              </SettingsAccordion>
              <SettingsAccordion title={t("backendSettingsPage.accessPolicy")}>
                <StyledTextArea
                  placeholder={t("common.ClickToAdd")}
                  value={accessPolicy}
                  rows={5}
                  onChange={handleAccessPolicyChange}
                />
              </SettingsAccordion>
            </TextDiv>
          </ColumnDiv>
        </ColumsWrapper>
        <SaveButtonWrapper>
          <SaveButton style={{ width: "auto" }} disabled={!changes} />
        </SaveButtonWrapper>
      </ContentWrapper>
      <ButtonWrapper>
        <HistoryButton />
      </ButtonWrapper>
    </PageWrapper>
  ) : (
    <Loader />
  );
}

const ChatModeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Spacing = styled.div`
  height: 24px;
`;
