export const lightTheme = {
  backgroundColor: "#F2F4EC",
  color: "#333333",
  cardTextColor: "#FFFFFF",
  hoverColor: "#D2F0E9",
  hoverTextColor: "#333333",
  hoverBorderColor: "#333333",
  buttonBackgroundColor: "#333333",
  borderColor: "transparent",
};

export const darkTheme = {
  backgroundColor: "#1E1E1E",
  color: "#FFFFFF",
  cardTextColor: "#FFFFFF",
  hoverColor: "#1E1E1E",
  hoverTextColor: "#FFFFFF",
  hoverBorderColor: "#FFFFFF",
  buttonBackgroundColor: "#333333",
  borderColor: "#FFFFFF",
};
