import styled from "@emotion/styled";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { removeFilter, setChildFilters } from "../../features/filtersSlice";
import DeleteButton from "../Buttons/DeleteButton";
import PlusButton from "../Buttons/PlusButton";
import DeleteDialog from "../Dialogs/DeleteDialog";
import FilterForm from "../Forms/FilterForm";
import { SortableWrapper } from "../Sortables/SortableItem";
import SortableList from "../Sortables/SortableList";

export default function FilterParameter({ parameter }) {
  const dispatch = useDispatch();

  const items = parameter.child_parameters ?? [];
  const [initialLength, setInitialLength] = useState(items.length);
  const [addContainer, setAddContainer] = useState();

  const [activeItemId, setActiveItemId] = useState();
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState();

  // Add the component to the view, when the user clicks Save, it set the container to nothing: onClick={() => { setAddContainer() }}
  function handleAdd() {
    setActiveItemId();
    setAddContainer(
      <FilterForm
        parentId={parameter.id}
        id={initialLength + 1}
        initialData={{ name: "", tag: "" }}
        onClick={() => {
          setAddContainer();
        }}
      />,
    );
    setInitialLength(initialLength + 1);
  }

  function handleEdit(item) {
    setActiveItemId(item.id);
    setAddContainer(
      <FilterForm
        parentId={parameter.id}
        id={item.id}
        initialData={{ name: item.key, tag: item.value }}
        onClick={() => {
          setActiveItemId();
          setAddContainer();
        }}
      />,
    );
  }

  function setItems(updatedItems) {
    dispatch(setChildFilters({ id: parameter.id, children: updatedItems }));
  }

  function handleRemove() {
    const index = items.findIndex(({ id }) => id === itemToDelete);
    dispatch(removeFilter({ parentId: parameter.id, index: index }));
    if (itemToDelete === activeItemId) {
      setAddContainer();
    }
  }

  const handleDelete = (item) => {
    setItemToDelete(item.id)
    setDeleteDialogOpen(true)
  }

  const renderListItem = (item) => (
    <SortableList.Item id={item.id}>
      <SortableWrapper selected={activeItemId === item.id} onClick={() => {
        handleEdit(item);
      }}>
        <Div>
          <StyledButton
            disabled={item.id === activeItemId}
          >
            <Title>{item.key}</Title>
            {item.value}
          </StyledButton>
        </Div>
        <DeleteButton handleDelete={() => {handleDelete(item)}} border={true} />
      </SortableWrapper>
    </SortableList.Item>
  );

  return (
    <ColumnWrapper key={parameter.key}>
      <ColumnTitle>{parameter.key}</ColumnTitle>
      <SortableList
        items={items}
        onChange={setItems}
        renderItem={renderListItem}
      />
      {addContainer}
      {!addContainer && <PlusButton handleAdd={handleAdd}/>}
      {isDeleteDialogOpen ? <DeleteDialog handleRemove={handleRemove} closeDialog={() => setDeleteDialogOpen(false)} /> : null}
    </ColumnWrapper>
  );
}

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  min-width: 20rem;

  text-align: left;
  gap: 20px;
`;

const ColumnTitle = styled.div`
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-large);
`;

const Title = styled.div`
  font-weight: 500;
  text-align: left;
`;

const StyledButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: start;

  padding-left: 0.75rem;
  padding-right: 0.75rem;
`;

const Div = styled.div`
  width: 100%;
`;
