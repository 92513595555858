import { createSlice } from "@reduxjs/toolkit";

export const tileSwitchSlice = createSlice({
  name: "tiles",
  initialState: {
    infos: [],
    tile: null,
  },
  reducers: {
    setTiles: (state, action) => {
      state.infos = action.payload;
    },
    setTile: (state, action) => {
      const tile = state.infos.find(
        (tile) => tile.key.toLowerCase() === action.payload.toLowerCase()
      );

      if (tile) {
        state.tile = tile;
      }
    },
    setTileMode: (state, action) => {
      const { key, value } = action.payload;
      const tileIndex = state.infos.findIndex(
        (tile) => tile.key.toLowerCase() === key.toLowerCase()
      );

      if (tileIndex !== -1) {
        state.infos[tileIndex].display = value;
        if (state.tile && state.tile.key.toLowerCase() === key.toLowerCase()) {
          state.tile.display = value;
        }
      }
    },
  },
});

export const { setTiles, setTile, setTileMode } = tileSwitchSlice.actions;
export default tileSwitchSlice.reducer;
