import { useParams } from "react-router-dom";
import { CardsWrapper } from "./Features";
import FeatureCard from "../components/Cards/FeatureCard";
import { edit, filter, star } from "@dnb/eufemia/icons";
import { useTranslation } from "react-i18next";
import ChatBotCard from "../components/Cards/ChatModeCard";
import { useDispatch, useSelector } from "react-redux";
import { setTile, setTileMode, setTiles } from "../features/tiles";
import { useEffect, useState } from "react";
import Loader from "../components/Loaders/Loader";
import api from "../apiConfig";
import toast from "react-hot-toast";
import store from "../store/store";

export default function SummaryMenu() {
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const tile = useSelector((state) => state.tiles.tile);
  const tiles = useSelector((state) => state.tiles.infos);
  const lang = useSelector((state) => state.lang.lang);

  if (params.bot !== "genai") {
    return <div>Unauthorized</div>;
  }

  useEffect(() => {
    document.title = `${params.bot.toUpperCase()}: ${params.tile.toUpperCase()}`;
  }, [params.bot]);

  useEffect(() => {
    if (tiles.length > 0) {
      dispatch(setTile(params.tile));
    } else {
      let isMounted = true; // fix: Can't perform a React state update on an unmounted component
      setIsLoading(true);
      api
        .get(`${params.bot}/filters`, {
          params: {
            lang: lang,
          },
        })
        .then((res) => {
          if (isMounted) {
            dispatch(setTiles(res.data.body.parameters));
            dispatch(setTile(params.tile));
            setIsLoading(false);
          }
        })
        .catch((err) => {
          toast.error("Unable to load tiles");
          console.error(err);
          setIsLoading(false);
        });

      return () => {
        isMounted = false;
      };
    }
  }, [params.bot, lang]);

  async function handleSave(value) {
    dispatch(
      setTileMode({
        key: params.tile,
        value: value === "ON" ? true : false,
      })
    );

    const updatedState = store.getState();
    const tiles = updatedState.tiles.infos;

    try {
      // We need to update the values in both languages
      await api.post(
        `${params.bot}/filters`,
        {
          parameters: tiles,
        },
        {
          params: {
            lang: "no",
          },
        }
      );
      await api.post(
        `${params.bot}/filters`,
        {
          parameters: tiles,
        },
        {
          params: {
            lang: "en",
          },
        }
      );
      toast.success(t("common.DataUpdated"));
    } catch (error) {
      toast.error(t("common.ErrorData"));
    }
  }

  return !isLoading ? (
    <CardsWrapper>
      {tile && (
        <ChatBotCard
          icon={edit}
          mode={t("summaryMenu.TileToggle")}
          description={t("summaryMenu.TileToggleDescription")}
          checked={tile.display === true ? "ON" : "OFF"}
          onChange={handleSave}
        />
      )}
      <FeatureCard
        title={t("summaryMenu.UiSettings")}
        description={t("summaryMenu.UiSettingsDescription")}
        link={"ui-settings"}
        icon={filter}
      />
      <FeatureCard
        title={t("summaryMenu.BackEndSettings")}
        description={t("summaryMenu.BackEndSettingsDescription")}
        link={"backend-settings"}
        icon={star}
      />
    </CardsWrapper>
  ) : (
    <Loader />
  );
}
