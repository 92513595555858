import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./styles/index.css";
import "./i18n";

import "@dnb/eufemia/style";
import store from "./store/store";
import { Provider } from "react-redux";

import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { config } from "./config";

export const pca = new PublicClientApplication({
  auth: {
    clientId: config.CLIENT_ID,
    authority: config.AUTHORITY,
    redirectUri: "/bots",
  },
});

pca.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    pca.setActiveAccount(event.payload);
  }
});

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App msalInstance={pca} />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);
