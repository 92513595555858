import { css } from "@emotion/react";
import styled from "@emotion/styled";

export default function RoundedSwitch({
  checked,
  onChange,
  variant = "primary",
}) {
  return (
    <StyledSwitch variant={variant}>
      <input type="checkbox" checked={checked} onChange={onChange} />
      <span className="slider round"></span>
    </StyledSwitch>
  );
}

const StyledSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 65px;
  height: 32px;

  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  & .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #333333;
    border: 1px solid ${(props) => props.theme.borderColor};
    transition: 0.4s;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${(props) =>
    props.variant === "secondary" &&
    css`
      & .slider {
        border: 1px solid white;
      }
    `}

  & .slider:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
  }

  & input:checked + .slider {
    background-color: #333333;
  }

  & input:focus + .slider {
    box-shadow: 0 0 1px #333333;
  }

  & input:checked + .slider:before {
    transform: translateX(30.5px);
  }

  /* Rounded sliders */
  & .slider.round {
    border-radius: 34px;
  }

  & .slider.round:before {
    border-radius: 50%;
  }
`;
