import { pin_medium } from "@dnb/eufemia/icons";
import { useDispatch } from "react-redux";
import { setChanges } from "../../features/changeSlice";
import { useNavigate } from "react-router-dom";
import Dialog from "./Dialog";
import useHandleSave from "../../hooks/useHandleSave";
import { useTranslation } from "react-i18next";

export default function SaveDialog({ open, setOpen, href }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleSave = useHandleSave();

  return (
    <>
      {open && (
        <Dialog
          title={t("dialogs.save.title")}
          icon={pin_medium}
          discardText={t("dialogs.save.discardText")}
          acceptText={t("dialogs.save.acceptText")}
          content={t("dialogs.save.content")}
          onClose={() => {
            handleSave();
            setOpen(false);
            href ? navigate(href) : navigate(-1);
          }}
          onConfirm={() => {
            dispatch(setChanges(false));
            setOpen(false);
            href ? navigate(href) : navigate(-1);
          }}
          onExit={() => setOpen(false)}
        />
      )}
    </>
  );
}
