import { createSlice } from "@reduxjs/toolkit";

const updateParameterByKey = (parameters, key, value) => {
  const parameter = parameters.find((param) => param.key === key);
  if (parameter) {
    parameter.value = value;
  }
};

export const welcomeSlice = createSlice({
  name: "welcome",
  initialState: {
    infos: null,
    main: "",
    sub: "",
    image: null,
    welcomeDisclaimer: "",
    disclaimerUrl: ""
  },
  reducers: {
    setInfos: (state, action) => {
      state.infos = action.payload;
      action.payload.parameters.forEach((parameter) => {
        switch (parameter.key) {
          case "welcomeTitle":
            state.main = parameter.value;
            break;
          case "welcomeMessage":
            state.sub = parameter.value;
            break;
          case "iconUrl":
            state.image = parameter.value + `?t=${Date.now()}`;
            break;
          case "welcomeDisclaimer":
            state.welcomeDisclaimer = parameter.value ;
            break;
          case "disclaimerUrl":
            state.disclaimerUrl = parameter.value;
            break;
        }
      });
    },
    setMain: (state, action) => {
      state.main = action.payload;
      updateParameterByKey(
        state.infos.parameters,
        "welcomeTitle",
        action.payload,
      );
    },
    setSub: (state, action) => {
      state.sub = action.payload;
      updateParameterByKey(
        state.infos.parameters,
        "welcomeMessage",
        action.payload,
      );
    },
    setWelcomeDisclaimer: (state, action) => {
      state.welcomeDisclaimer = action.payload;
      updateParameterByKey(
        state.infos.parameters,
        "welcomeDisclaimer",
        action.payload,
      );
    },
    setDisclaimerUrl: (state, action) => {
      state.disclaimerUrl = action.payload;
      updateParameterByKey(
        state.infos.parameters,
        "disclaimerUrl",
        action.payload,
      );
    },
    setImage: (state, action) => {
      state.image = action.payload;
      updateParameterByKey(state.infos.parameters, "iconUrl", action.payload);
    },
    resetInfos: (state) => {
      state.infos = null;
      state.main = "";
      state.sub = "";
      state.image = null;
    },
  },
});

export const { setInfos, setMain, setSub, setImage, resetInfos, setWelcomeDisclaimer, setDisclaimerUrl  } =
  welcomeSlice.actions;
export default welcomeSlice.reducer;
