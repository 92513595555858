import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      header: {
        bots: "CHAT ADMIN PANEL",
        aino: "AINO",
        genai: "GENAI",
        juno: "JUNO",
        fix: "FIX",
        hugo: "HUGO",
        justina: "JUSTINA",
        quickmenu: "QUICK MENU",
        welcomepage: "WELCOME PAGE",
        filters: "FILTERS",
        chatmode: "CHAT MODE",
        prompt: "SYSTEM PROMPT",
        uisettings: "UI SETTINGS",
        backendsettings: "SUMMARY - BACKEND SETTINGS",
      },
      common: {
        ClickToAdd: "Click to add...",
        Rearrange: "Rearrange",
        DataUpdated: "Data updated successfully!",
        ErrorData: "Error while saving data",
        Language: "Language",
        Mode: "Mode",
        Settings: "Settings",
        Close: "Close",
      },
      layout: {
        Back: "Back",
        LogOff: "LOG OFF",
        History: "History",
        Preview: "Preview",
        Save: "Save",
        Add: "Add",
      },
      features: {
        QuickMenu: "QUICK MENU",
        QuickMenuDescription: "Add, edit and delete links in Quick Menu.",
        WelcomePage: "WELCOME PAGE",
        WelcomePageDescription: "Change icon and text for Welcome Page.",
        Filters: "FILTERS",
        FiltersDescription: "Add, edit and delete filters.",
        ChatMode: "CHAT MODE",
        ChatModeDescription: "Turn the agent and bot mode off and on.",
        Prompt: "SYSTEM PROMPT",
        PromptDescription: "Customize the system prompt.",
        Tile: "TILES",
        TileDescription: "Customize the tiles",
      },
      welcomePage: {
        BrowseForPhoto: "Browse photos",
        Icon: "Icon",
        Text: "Text",
        MainHeader: "Main header",
        SubHeader: "Sub header",
        ClickToAdd: "Click to add...",
        DisclaimerText: "Disclaimer Text",
        DisclaimerURL: "Disclaimer URL",
      },
      summaryMenu: {
        TileToggle: "TILE",
        TileToggleDescription: "Turn tile off and on",
        UiSettings: "UI SETTINGS",
        UiSettingsDescription:
          "Edit, name, description, link in description and welcome message.",
        BackEndSettings: "BACKEND SETTINGS",
        BackEndSettingsDescription:
          "Edit backend settings like input and output settings, edit prompt.",
        AddNewTile: "Add new tile",
      },
      menuPage: {
        Name: "Name",
        URL: "URL",
      },
      filterPage: {
        Subtitle: "Remember that routing tags are case sensitive.",
        FilterName: "Filter name",
        RoutingTag: "Routing tag",
      },
      chatModePage: {
        agent: "AGENT",
        bot: "BOT",
        description: "Turn mode off and on",
      },
      tileSwitch: {
        tile: "TILE",
        description: "Tile on and off",
      },
      promptPage: {
        type: "Type prompt here",
      },
      history: {
        add: "Added",
        edit: "Edited",
        delete: "Deleted",
      },
      uiSettingsPage: {
        tileName: "Tile Name",
        tileDescription: "Tile Description",
        buttonText: "Button Text",
        description: "Click to add...",
      },
      backendSettingsPage: {
        characterLimits: "Character limits",
        outputCharacterLimit: "Output character limit",
        inputCharacterLimit: "Input character limit",
        gpt: "GPT",
        gptModel: "GPT model",
        gptType: "GPT type",
        prompts: "Prompts",
        welcomeMessage: "Welcome message",
        accessPolicy: "Access policy",
        sensitiveInfo: "SENSITIVE INFO",
        context: "CONTEXT",
        description: "Turn mode off and on",
      },

      welcomeTitle: "Main header",
      welcomeMessage: "Sub header",
      dialogs: {
        delete: {
          title: "Are you sure you want to delete this?",
          discardText: "Cancel",
          acceptText: "Delete",
          content: "This action cannot be undone.",
        },
        save: {
          title: "Changes have been made...",
          discardText: "Save",
          acceptText: "Discard",
          content: "Would you like to save your changes or discard them ?",
        },
      },
      langswitch: {
        header: "Select language for change",
      },
    },
  },
  no: {
    translation: {
      header: {
        bots: "CHAT ADMIN PANEL",
        aino: "AINO",
        genai: "GENAI",
        juno: "JUNO",
        fix: "FIX",
        hugo: "HUGO",
        justina: "JUSTINA",
        quickmenu: "HURTIGMENY",
        welcomepage: "VELKOMSTSIDE",
        filters: "FILTRE",
        chatmode: "CHAT MODUS",
        prompt: "SYSTEMPROMPT",
        uisettings: "UI-INNSTILLINGER",
        backendsettings: "SAMMENDRAG - BAKENDINNSTILLINGER",
      },
      common: {
        ClickToAdd: "Klikk for å legge til...",
        Rearrange: "Omorganiser",
        DataUpdated: "Data oppdatert vellykket!",
        ErrorData: "Feil under lagring av data",
        Language: "Språk",
        Mode: "Mode",
        Settings: "Innstillinger",
        Close: "Lukk",
      },
      layout: {
        Back: "Tilbake",
        LogOff: "LOGG AV",
        History: "Historie",
        Preview: "Forhåndsvisning",
        Save: "Lagre",
        Add: "Legg til",
      },
      features: {
        QuickMenu: "HURTIGMENY",
        QuickMenuDescription: "Legg til, endre og slett lenker i hurtigmenyen.",
        WelcomePage: "VELKOMSTSIDE",
        WelcomePageDescription: "Endre ikon og tekst på velkomstsiden.",
        Filters: "FILTRE",
        FiltersDescription: "Legg til, endre og slett filtre.",
        ChatMode: "CHAT MODUS",
        ChatModeDescription: "Slå agent- og botmodus av og på.",
        Prompt: "SYSTEMPROMPT",
        PromptDescription: "Tilpass systemforespørselen.",
      },
      welcomePage: {
        BrowseForPhoto: "Bla gjennom",
        Icon: "Ikon",
        Text: "Tekst",
        MainHeader: "Hovedoverskrift",
        SubHeader: "Undertittel",
        ClickToAdd: "Klikk for å legge til...",
        DisclaimerText: "Vilkår for bruk",
        DisclaimerURL: "Vilkår for bruk URL",
      },
      summaryMenu: {
        TileToggle: "FLIS",
        TileToggleDescription: "Slå flisen av og på",
        UiSettings: "UI-INNSTILLINGER",
        UiSettingsDescription:
          "Rediger, navn, beskrivelse, lenke i beskrivelse og velkomstmelding.",
        BackEndSettings: "BAKEND-INNSTILLINGER",
        BackEndSettingsDescription:
          "Rediger bakend-innstillinger som innskudds- og utsettings, rediger oppfordring.",
        AddNewTile: "Legg til ny flis",
      },
      menuPage: {
        Name: "Navn",
        URL: "URL",
      },
      filterPage: {
        Subtitle: "Husk at rutingtagger er skriftsensitive.",
        FilterName: "Filternavn",
        RoutingTag: "Rutingtagg",
      },
      chatModePage: {
        agent: "AGENT",
        bot: "BOT",
        description: "Slå modus av og på",
      },
      promptPage: {
        type: "Skriv ledetekst her",
      },
      history: {
        add: "La til",
        edit: "Redigerte",
        delete: "Slettet",
      },
      uiSettingsPage: {
        tileName: "Flisnavn",
        tileDescription: "Flisbeskrivelse",
        buttonText: "Knappetekst",
        description: "Klikk for å legge til...",
      },
      backendSettingsPage: {
        characterLimits: "Tegnbegrensninger",
        outputCharacterLimit: "Utdatategngrense",
        inputCharacterLimit: "Skriv inn tegngrense",
        gpt: "GPT",
        gptModel: "GPT-modell",
        gptType: "GPT-type",
        prompts: "Prompts",
        welcomeMessage: "Velkomstmelding",
        accessPolicy: "Tilgangspolitikk",
        sensitiveInfo: "FØLSOM INFO",
        context: "KONTEKST",
        description: "Slå modus av og på",
      },
      welcomeTitle: "Hovedoverskrift",
      welcomeMessage: "Undertittel",
      dialogs: {
        delete: {
          title: "Er du sikker på at du vil slette dette?",
          discardText: "Avbryt",
          acceptText: "Slett",
          content: "Denne handlingen kan ikke angres.",
        },
        save: {
          title: "Endringer har blitt gjort...",
          discardText: "Lagre",
          acceptText: "Forkast",
          content: "Vil du lagre endringene eller forkaste dem?",
        },
      },
      langswitch: {
        header: "Velg språk for endringer",
      },
    },
  },
};

const userLanguage = navigator.language || navigator.userLanguage;
const formattedLanguage = userLanguage.substr(0, 2).toLowerCase();

const languageMapping = {
  en: "en",
  no: "no",
};

const detectedLanguage =
  localStorage.getItem("lang") || languageMapping[formattedLanguage] || "en";

i18n.use(initReactI18next).init({
  resources,
  lng: detectedLanguage,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
