import styled from "@emotion/styled";
import { Popover, Transition } from "@headlessui/react";
import LangSwitch from "../Switchs/LangSwitch";
import ThemeSwitcher from "../Switchs/ThemeSwitcher";
import { Icon } from "@dnb/eufemia";
import { cog_medium } from "@dnb/eufemia/icons";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import LogoutButton from "../Buttons/LogoutButton";

export default function SettingsPopover() {
  const { t } = useTranslation();

  return (
    <StyledPopover>
      <PopoverButton>
        <Icon icon={cog_medium} />
      </PopoverButton>
      <Transition
        as={Fragment}
        enter="enter"
        enterFrom="enterFrom"
        enterTo="enterTo"
        leave="leave"
        leaveFrom="leaveFrom"
        leaveTo="leaveTo"
      >
        <PopoverPanel>
          <Title>{t("common.Settings")}</Title>
          <PopoverSwitchWrapper>
            <ThemeSwitcher />
            <div>{t("common.Mode")}</div>
          </PopoverSwitchWrapper>
          <PopoverSwitchWrapper>
            <div>
              <LangSwitch />
            </div>
            <div>{t("common.Language")}</div>
          </PopoverSwitchWrapper>
          <PopoverSwitchWrapper>
            <LogoutButton />
          </PopoverSwitchWrapper>
        </PopoverPanel>
      </Transition>
    </StyledPopover>
  );
}

const StyledPopover = styled(Popover)`
  position: relative;

  .enter {
    transition: all 200ms ease-out;
    opacity: 1;
    transform: translateY(0);
  }

  .enterFrom {
    opacity: 0;
    transform: translateY(0.25rem);
  }

  .enterTo {
    opacity: 1;
    transform: translateY(0);
  }

  .leave {
    transition: all 150ms ease-in;
    opacity: 0;
    transform: translateY(0.25rem);
  }

  .leaveFrom {
    opacity: 1;
    transform: translateY(0);
  }

  .leaveTo {
    opacity: 0;
    transform: translateY(0.25rem);
  }
`;

const PopoverSwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const PopoverButton = styled(Popover.Button)`
  border: 1px solid ${(props) => props.theme.color};
  padding: 6px;
  border-radius: 50%;
  color: ${(props) => props.theme.color};

  width: fit-content;
  display: flex;
  flex-direction: center;
  align-items: center;

  &:hover {
    background-color: ${(props) => props.theme.color};
    color: ${(props) => props.theme.backgroundColor};
  }
`;

const PopoverPanel = styled(Popover.Panel)`
  position: absolute;
  left: 0px;
  bottom: 100%;
  margin-bottom: 10px;
  z-index: 10;

  min-width: 190px;
  width: max-content;

  padding: 1rem;

  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.hoverBorderColor};
  background-color: ${(props) => props.theme.backgroundColor};

  display: flex;
  flex-direction: column;

  gap: 20px;
`;

const Title = styled.div`
  align-self: center;
`;
