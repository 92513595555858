import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleLang } from "../../features/langSlice";
import Switch from "./Switch";

export default function LangSwitch() {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.lang.lang);

  const [checked, setChecked] = useState(lang === "no" ? false : true);

  function handleOnChange() {
    setChecked(checked ? false : true);
    dispatch(toggleLang());
  }

  return <Switch type="checkbox" checked={checked} onChange={handleOnChange} />;
}
