import { createSlice } from "@reduxjs/toolkit";

export const promptSlice = createSlice({
  name: "prompt",
  initialState: {
    startPrompt: "",
    sendPrompt: ""
  },
  reducers: {
    setStartPrompt: (state, action) => {
      state.startPrompt = action.payload;
    },
    setSendPrompt: (state, action) => {
      state.sendPrompt = action.payload;
    },
  },
});

export const { setStartPrompt, setSendPrompt } = promptSlice.actions;
export default promptSlice.reducer;
