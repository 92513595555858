import { Fragment, useMemo, useState } from "react";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";

import { DragHandle, SortableItem } from "./SortableItem";
import { SortableOverlay } from "./SortableOverlay";
import { createElement } from "react";

export default function SortableList({ items, onChange, renderItem }) {
  const [active, setActive] = useState(null);
  const activeItem = useMemo(
    () => items.find((item) => item.id === active?.id),
    [active, items],
  );
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  return createElement(
    DndContext,
    {
      sensors: sensors,
      onDragStart: ({ active }) => {
        setActive(active);
      },
      onDragEnd: ({ active, over }) => {
        if (over && active.id !== over?.id) {
          const activeIndex = items.findIndex(({ id }) => id === active.id);
          const overIndex = items.findIndex(({ id }) => id === over.id);

          const updatedItems = arrayMove(items, activeIndex, overIndex);
          onChange(updatedItems);
        }
        setActive(null);
      },
      onDragCancel: () => {
        setActive(null);
      },
    },
    createElement(
      SortableContext,
      { items: items },
      createElement(
        "ul",
        { className: "SortableList", role: "application" },
        items.map((item) =>
          createElement(Fragment, { key: item.id }, renderItem(item)),
        ),
      ),
    ),
    createElement(
      SortableOverlay,
      null,
      activeItem ? renderItem(activeItem) : null,
    ),
  );
}

SortableList.Item = SortableItem;
SortableList.DragHandle = DragHandle;
