import { Icon, Tooltip } from "@dnb/eufemia";
import { hamburger } from "@dnb/eufemia/icons";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "@emotion/styled";
import { createContext, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

const SortableItemContext = createContext({
  attributes: {},
  listeners: undefined,
  ref() { },
});

export function SortableItem({ children, id }) {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id });
  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, listeners, setActivatorNodeRef],
  );
  const style = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <SortableItemContext.Provider value={context}>
      <li className="SortableItem" ref={setNodeRef} style={style}>
        {children}
      </li>
    </SortableItemContext.Provider>
  );
}

export function DragHandle() {
  const { attributes, listeners, ref } = useContext(SortableItemContext);

  const { t } = useTranslation();

  return (
    <StyledTooltip
      targetElement={
        <StyledButton
          className="DragHandle"
          {...attributes}
          {...listeners}
          ref={ref}
        >
          <Icon icon={hamburger} />
        </StyledButton>
      }
    >
      {t("common.Rearrange")}
    </StyledTooltip>
  );
}

const StyledTooltip = styled(Tooltip)`
  background-color: ${(props) => props.theme.color};
  color: ${(props) => props.theme.backgroundColor};
`;

const StyledButton = styled.button`
  &:hover {
    cursor: grab;
  }
`;

export const SortableWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 20rem;
  cursor: pointer;

  background-color: #333333;
  background-color: ${(props) => props.selected && props.theme.hoverColor};

  &:hover {
    background-color: ${(props) => props.theme.hoverColor};
    color: ${(props) => props.theme.hoverTextColor};
    border: 1px solid ${(props) => props.theme.hoverBorderColor};
  }

  color: ${(props) => props.selected ? props.theme.hoverTextColor : props.theme.cardTextColor};

  padding: var(--spacing-small);

  border: 1px solid ${(props) => props.selected ? props.hoverBorderColor : props.theme.cardTextColor} ;
  border-radius: 8px;
`;
