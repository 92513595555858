import { Icon } from "@dnb/eufemia";
import { close } from "@dnb/eufemia/icons";
import { RoundedButton } from "./RoundedButton";

export default function DeleteButton({ handleDelete }) {

    const handleDeleteButtonClick = (event) => {
      handleDelete()
      event.stopPropagation()
    }

    return (
      <RoundedButton onClick={handleDeleteButtonClick} >
        <Icon icon={close} />
      </RoundedButton>
    );
  }