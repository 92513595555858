import { Icon } from "@dnb/eufemia";
import { add } from "@dnb/eufemia/icons";
import styled from "@emotion/styled";

export default function PlusButton({ handleAdd}) {
  return (
    <RoundedButton onClick={handleAdd}>
      <Icon icon={add} />
    </RoundedButton>
  );
}

const RoundedButton = styled.button`
  border: 1px solid ${(props) => props.theme.color};
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: "transparent";
  color: ${(props) => props.theme.color} ;

  &:hover {
    background-color: ${(props) => props.theme.color};
    color: ${(props) => props.theme.backgroundColor};
  }
`;
