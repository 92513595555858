import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import api from "../apiConfig";
import HistoryButton from "../components/Buttons/HistoryButton";
import SaveButton from "../components/Buttons/SaveButton";
import Loader from "../components/Loaders/Loader";
import { setSendPrompt, setStartPrompt } from "../features/promptSlice";

export default function Prompt() {
  const {startPrompt, sendPrompt} = useSelector((state) => state.prompt);
  const params = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const lang = useSelector((state) => state.lang.queriesLang);

  const textAreaRef = useRef(null);

  const [initialStartPrompt, setInitialStartPrompt] = useState("")
  const [initialSendPrompt, setInitialSendPrompt] = useState("")
  const [startPromptChanged, setStartPromptChanged] = useState(false);
  const [sendPromptChanged, setSendPromptChanged] = useState(false);

  useEffect(() => document.title = `${params.bot.toUpperCase()}: Prompts`, [])


  useEffect(() => {
    setIsLoading(true);
    // Fetching the welcome page infos
    api
      .get(`${params.bot}/prompt`, {
        params: {
          lang: lang,
        },
      })
      .then((res) => {

        dispatch(setStartPrompt(res.data.startPrompt));
        dispatch(setSendPrompt(res.data.sendPrompt));
        setInitialStartPrompt(res.data.startPrompt);
        setInitialSendPrompt(res.data.sendPrompt);
        setIsLoading(false);
      })
      .catch(() => {
        toast.error("Unable to load the system prompt");
        setIsLoading(false);
      });

  }, [params.bot, dispatch, lang]);

  function handleStartPromptChange(e) {
    dispatch(setStartPrompt(e.target.value));
    setStartPromptChanged(!(initialStartPrompt === e.target.value));
  }


  function handleSendPromptChange(e) {
    dispatch(setSendPrompt(e.target.value));
    setSendPromptChanged(!(initialSendPrompt === e.target.value));
  }

  return !isLoading ? (
    <PromptWrapper>
      <TitleWrapper>{t("promptPage.type")}</TitleWrapper>
      <TextArea
        placeholder={t("common.ClickToAdd")}
        ref={textAreaRef}
        onChange={handleStartPromptChange}
        value={startPrompt}
      />
      
      <TitleWrapper>{t("promptPage.type")}</TitleWrapper>
      <TextArea
        placeholder={t("common.ClickToAdd")}
        ref={textAreaRef}
        onChange={handleSendPromptChange}
        value={sendPrompt}
        />
      <ButtonWrapper>
        <SaveButton disabled={!(startPromptChanged || sendPromptChanged)}/>
        <HistoryButton />
      </ButtonWrapper>
    </PromptWrapper>
  ) : (
    <Loader />
  );
}

const PromptWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 10px;
`;

const TextArea = styled.textarea`
  height: 25vh;
  width: 50vw;

  background-color: ${(props) => props.theme.buttonBackgroundColor};
  color: #ffffff;
  border-radius: 8px;
  border-color: #ffffff;

  margin-bottom: 20px;
  padding: 8px;
`;

const TitleWrapper = styled.div`
  font-weight: 500;
  font-size: 18px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 10px;

  width: fit-content;
`;
