/* eslint-disable no-unused-vars */

import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import api from "../apiConfig";
import HistoryButton from "../components/Buttons/HistoryButton";
import SaveButton from "../components/Buttons/SaveButton";
import Loader from "../components/Loaders/Loader";
import { useTranslation } from "react-i18next";
import {
  setInfos,
  setTileName,
  setTileDescription,
  setGoToLink,
  resetInfos,
} from "../features/uisettingsSlice.js";
import { Label, FormWrapper, InputWrapper } from "../components/Forms/Form.jsx";
import SettingsAccordion from "../components/Accordions/SettingsAccordion.jsx";
import {
  ButtonWrapper,
  ColumnDiv,
  ColumsWrapper,
  ContentWrapper,
  Divider,
  PageWrapper,
  SaveButtonWrapper,
  StyledTextArea,
  TextDiv,
  StyledInput,
} from "../components/Forms/SettingsForm.jsx";
import styled from "@emotion/styled";

export default function UiSettings() {
  const dispatch = useDispatch();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const { tileName, tileDescription, goToLink } = useSelector(
    (state) => state.uisettings
  );

  const lang = useSelector((state) => state.lang.queriesLang);
  const changes = useSelector((state) => state.change.changes);

  function handleTileNameChange(e) {
    dispatch(setTileName(e.target.value));
  }

  function handleTileDescriptionChange(e) {
    dispatch(setTileDescription(e.target.value));
  }

  function handleGoToLinkChange(e) {
    dispatch(setGoToLink(e.target.value));
  }

  useEffect(
    () => (document.title = `${params.tile.toUpperCase()}: UI Settings`),
    []
  );

  useEffect(() => {
    setIsLoading(true);

    api
      .get("/appconfig", {
        params: {
          lang: lang,
          key: params.tile,
        },
      })
      .then((res) => {
        // dispatch(setInfos(res.data.body));
        dispatch(
          setInfos({
            tileName: undefined,
            tileDescription: undefined,
            goToLink: undefined,
          })
        );
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Unable to load UI Settings");
        console.error(err);
        setIsLoading(false);
      });

    return () => {
      dispatch(resetInfos());
    };
  }, [params.bot, dispatch, lang]);

  return !isLoading ? (
    <PageWrapper>
      <ContentWrapper>
        <ColumsWrapper>
          <ColumnDiv>
            <TextDiv>
              <FormWrapper>
                <InputWrapper>
                  <Label>{t("uiSettingsPage.tileName")}</Label>
                  <StyledInput
                    placeholder={t("uiSettingsPage.description")}
                    value={tileName}
                    onChange={handleTileNameChange}
                  />
                </InputWrapper>
                <InputWrapper>
                  <Label>{t("uiSettingsPage.buttonText")}</Label>
                  <StyledInput
                    placeholder={t("uiSettingsPage.description")}
                    value={goToLink}
                    onChange={handleGoToLinkChange}
                    rows={2}
                  />
                </InputWrapper>
              </FormWrapper>
            </TextDiv>
          </ColumnDiv>
          <Divider />
          <ColumnDiv>
            <Spacing />
            <SettingsAccordion title={t("uiSettingsPage.tileDescription")}>
              <StyledTextArea
                placeholder={t("uiSettingsPage.description")}
                value={tileDescription}
                rows={4}
                onChange={handleTileDescriptionChange}
              />
            </SettingsAccordion>
          </ColumnDiv>
        </ColumsWrapper>
        <SaveButtonWrapper>
          <SaveButton style={{ width: "auto" }} disabled={!changes} />
        </SaveButtonWrapper>
      </ContentWrapper>
      <ButtonWrapper>
        <HistoryButton />
      </ButtonWrapper>
    </PageWrapper>
  ) : (
    <Loader />
  );
}

const Spacing = styled.div`
  height: 7px;
`;
