import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { ThemeProvider } from "@emotion/react";
import { useParams } from "react-router-dom";
import { Button, Div, Dropdown, P } from "@dnb/eufemia";
import CloseButton from "../Buttons/CloseButton";
import api from "../../apiConfig";
import toast from "react-hot-toast";

export default function Preview({ main, sub, icon, onClose }) {
  const [theme, setTheme] = useState();
  const params = useParams();

  const data = ["Privat", "Bedrift"];

  useEffect(() => {
    api
      .get(`${params.bot}/theme`)
      .then((res) => {
        setTheme(res.data.body.theme);
      })
      .catch((err) => {
        toast.error("Failed to load theme")
        console.error(err);
      });
  }, [params.bot]);

  return (
    <>
      {theme && (
        <ThemeProvider theme={theme}>
          <Overlay onClick={onClose} />
          <PreviewWrapper>
            <HeaderWrapper>
              <CloseButton handleClose={onClose} />
            </HeaderWrapper>
            <ChatFilter>
              <DropdownWrapper
                title="Velg filter"
                data={data}
              ></DropdownWrapper>
            </ChatFilter>
            <IconContainer>
              <Img src={icon} alt={main} />
            </IconContainer>
            <WelcomeMessageWrapper>
              <WelcomeMessageHeading>{main}</WelcomeMessageHeading>
              <WelcomeMessageTextWrapper>
                <WelcomeMessageText>{sub}</WelcomeMessageText>
              </WelcomeMessageTextWrapper>
            </WelcomeMessageWrapper>

            <WelcomeButtonWrapper>
              <ButtonWrapper>Start chat</ButtonWrapper>
            </WelcomeButtonWrapper>
          </PreviewWrapper>
        </ThemeProvider>
      )}
    </>
  );
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 999;
`;

const PreviewWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 0.5rem;
  width: 80%;
  height: 80%;

  @media screen and (max-height: 1000px) {
    width: 90%;
    height: 90%;
  }

  background-color: ${(props) =>
    props.theme.colors.secondary.secondary_background};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: var(--spacing-small);
  padding-bottom: 1rem;

  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  width: 100%;

  background-color: ${(props) => props.theme.colors.primary};
  min-height: 4rem;
`;

const ChatFilter = styled.div`
  margin-top: var(--spacing-x-small);
`;

const DropdownWrapper = styled(Dropdown)`
  min-width: 176px;
  max-width: 16rem;

  &.dnb-dropdown--stretch {
    flex-grow: 0;
  }

  .dnb-dropdown__inner {
    width: 100%;
  }

  .dnb-drawer-list__option {
    color: ${(props) => props.theme.colors.secondary.secondary_text_in_chat};
    background-color: white;
  }

  .dnb-drawer-list__option--selected {
    color: white;
    background-color: ${(props) => props.theme.colors.primary};
    :hover:not([disabled]) {
      background-color: ${(props) => props.theme.colors.primary} !important;
    }
  }

  .dnb-button--active {
    --border-color: ${(props) => props.theme.colors.primary} !important;
    .dnb-dropdown__icon {
      color: ${(props) => props.theme.colors.primary};
    }
  }

  .dnb-button__text {
    color: ${(props) => props.theme.colors.secondary.secondary_text_in_chat};
  }

  .dnb-button--secondary {
    --border-color: ${(props) =>
      props.theme.colors.secondary.secondary_outline_inactive};

    .dnb-dropdown__icon {
      color: ${(props) =>
        props.theme.colors.secondary.secondary_outline_inactive};
    }

    :hover:not([disabled]) {
      --border-color: ${(props) => props.theme.colors.primary} !important;
      .dnb-button__text {
        color: ${(props) => props.theme.colors.primary};
      }
      .dnb-dropdown__icon {
        color: ${(props) => props.theme.colors.primary};
      }
    }
    :active:not([disabled]) {
      background-color: ${(props) => props.theme.colors.primary} !important;
      .dnb-button__text {
        color: white !important;
      }
      .dnb-dropdown__icon {
        color: white;
      }
    }
    :focus:not([disabled]) {
      --border-color: ${(props) => props.theme.colors.primary} !important;
    }
  }
`;

const IconContainer = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;

  @media screen and (max-height: 700px) {
    margin-top: 2rem;
  }
`;

const Img = styled.img`
  width: 5rem;
  height: 5rem;
`;

const WelcomeMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 65%;
  text-align: center;
  color: ${(props) => props.theme.colors.secondary.secondary_text_in_chat};
`;

const WelcomeMessageHeading = styled.div`
  font-weight: 500;
  font-size: var(--font-size-large);
`;

const WelcomeMessageTextWrapper = styled.div`
  margin-top: 1rem;
`;

const WelcomeMessageText = styled(P)`
  margin-bottom: 1rem;
`;

const WelcomeButtonWrapper = styled(Div)`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonWrapper = styled(Button)`
  background-color: ${(props) => props.theme.colors.primary};
  &:active:not([disabled]) {
    box-shadow: 0 0 0 0.0625rem ${(props) => props.theme.colors.primary};
    background-color: ${(props) =>
      props.theme.colors.secondary.secondary_background};
    color: ${(props) => props.theme.colors.secondary.secondary_text_in_chat};
  }

  &:hover {
    box-shadow: 0 0 0 0.125rem ${(props) => props.theme.colors.primary};
    background-color: ${(props) =>
      props.theme.colors.secondary.secondary_background};
    color: ${(props) => props.theme.colors.secondary.secondary_text_in_chat};
  }
`;
