import styled from "@emotion/styled";
import { Icon } from "@dnb/eufemia";
import Button from "../Buttons/Button";
import CloseButton from "../Buttons/CloseButton";

export default function Dialog({
  title,
  content,
  icon,
  discardText,
  acceptText,
  onClose,
  onConfirm,
  onExit,
}) {
  return (
    <div>
      <OverlayStyle onClick={onExit} />
      <DialogWrapper>
        <CloseButton handleClose={onExit}/>
        <Icon icon={icon} />
        <TextDiv>
          <Title>{title}</Title>
          <div>{content}</div>
        </TextDiv>
        <ButtonWrapper>
          <StyledButton onClick={onConfirm}>{acceptText}</StyledButton>
          <Button variant="secondary" onClick={onClose}>
            {discardText}
          </Button>
        </ButtonWrapper>
      </DialogWrapper>
    </div>
  );
}

const StyledButton = styled(Button)`
  border: 1px solid white;
`;

const Title = styled.div`
  padding-top: 10px;
  font-weight: var(--font-weight-medium);
  font-size: 24px;
`;

const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 0.5rem;

  margin-bottom: 0.8rem;
`;

const DialogWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);

  background-color: #313131;
  color: #ffffff;

  border: 1px solid white;
  border-radius: 0.625rem;
  padding: 2rem;

  gap: 1rem;

  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const OverlayStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 999;
`;
