import { createSlice } from "@reduxjs/toolkit";

export const chatModeSlice = createSlice({
  name: "chatmode",
  initialState: {
    modes: {},
  },
  reducers: {
    setModes: (state, action) => {
      state.modes = action.payload;
    },
    setAgentCheck: (state, action) => {
      state.modes.agent = action.payload;
    },
    setBotCheck: (state, action) => {
      state.modes.bot = action.payload;
    },
  },
});

export const { setModes, setAgentCheck, setBotCheck } = chatModeSlice.actions;
export default chatModeSlice.reducer;
