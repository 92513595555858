import { Icon } from "@dnb/eufemia";
import styled from "@emotion/styled";
import { useState } from "react";
import RoundedSwitch from "../Switchs/RoundedSwitch";

export default function ChatBotCard({
  mode,
  description,
  checked,
  onChange,
  icon,
}) {
  const [isChecked, setIsChecked] = useState(checked === "ON" ? true : false);

  function handleOnChange() {
    setIsChecked(!isChecked);
    onChange(isChecked ? "OFF" : "ON");
  }

  return (
    <ChatBotCardWrapper>
      <Icon icon={icon} size={"medium"} />
      <TextWrapper>
        <Title>{mode}</Title>
        <Description>{description}</Description>
      </TextWrapper>
      <SwitchWrapper>
        <RoundedSwitch
          checked={isChecked}
          onChange={handleOnChange}
          variant="secondary"
        />
        <LabelWrapper>
          <span className={`${!isChecked ? "active" : ""}`}>OFF</span>
          <span className={`${isChecked ? "active" : ""}`}>ON</span>
        </LabelWrapper>
      </SwitchWrapper>
    </ChatBotCardWrapper>
  );
}

const ChatBotCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  background-color: #333333;
  border-radius: 0.5rem;

  border: 1px solid ${(props) => props.theme.borderColor};

  padding: 2rem;

  width: 240px;
  height: 240px;
  text-decoration: none;

  color: ${(props) => props.theme.cardTextColor};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const Title = styled.div`
  font-size: var(--font-size-large);
  font-weight: 700;
  white-space: nowrap;
`;

const Description = styled.div`
  text-align: center;
`;

const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  width: 100%;
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 80%;
  font-size: var(--font-size-medium);
  & .active {
    font-weight: 900;
  }
`;
