import { Icon } from "@dnb/eufemia";
import { close } from "@dnb/eufemia/icons";
import { RoundedButton } from "./RoundedButton";

export default function CloseButton({ handleClose }) {
  return (
    <RoundedButton onClick={handleClose} border='1px solid'>
      <Icon icon={close} />
    </RoundedButton>
  );
}
