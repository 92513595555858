import styled from "@emotion/styled";

export const RoundedButton = styled.button`
  :hover {
    background-color: ${(props) => props.theme.color};
    color: ${(props) => props.theme.backgroundColor};
  }
  border: ${(props) => props.theme.color} ${props => props.border};
  border-radius: 50%;

  width: 24px;
  height: 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  align-self: end;
  top: 20px;
`;
