import AddButton from "../Buttons/AddButton";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addLink, editLink } from "../../features/menuSlice";
import CloseButton from "../Buttons/CloseButton";
import {
  Label,
  FormWrapper,
  StyledInput,
  InputWrapper,
  ButtonWrapper,
} from "./Form";
import { useTranslation } from "react-i18next";

export default function MenuForm({ onClick, id, initialData }) {
  const [name, setName] = useState(initialData.name);
  const [url, setUrl] = useState(initialData.url);
  const [nameChanged, setNameChanged] = useState(false);
  const [urlChanged, setUrlChanged] = useState(false);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(()=> {
    setName(initialData.name)
    setUrl(initialData.url)
  }, [initialData])

  function handleSubmit(e) {
    e.preventDefault();

    const payload = {
      id: id,
      key: name,
      value: url,
    };

    dispatch(initialData.name !== "" ? editLink(payload) : addLink(payload));
    onClick();
  }

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <CloseButton handleClose={onClick} />
      <InputWrapper>
        <Label>{t("menuPage.Name")}</Label>
        <StyledInput
          placeholder={t("common.ClickToAdd")}
          value={name}
          required
          onChange={(e) => {
            setName(e.target.value);
            setNameChanged(e.target.value != initialData.name)
          }}
        />
      </InputWrapper>
      <InputWrapper>
        <Label>{t("menuPage.URL")}</Label>
        <StyledInput
          placeholder={t("common.ClickToAdd")}
          value={url}
          required
          onChange={(e) => {
            setUrl(e.target.value);
            setUrlChanged(e.target.value != initialData.url)
          }}
        />
      </InputWrapper>
      <ButtonWrapper>
        <AddButton editMode={initialData.name && initialData.url} disabled={!(nameChanged || urlChanged)}/>
      </ButtonWrapper>
    </FormWrapper>
  );
}
