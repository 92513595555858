import styled from "@emotion/styled";

export default function ImageLoader() {
  return <ImageSkeleton></ImageSkeleton>;
}

const ImageSkeleton = styled.div`
  width: 4.0625rem;
  height: 4.0625rem;
  border-radius: 50%;

  background-color: lightgrey;
`;
