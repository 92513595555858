import styled from "@emotion/styled";

export default function Loader() {
  return <LoaderWrapper></LoaderWrapper>;
}

const LoaderWrapper = styled.div`
  border: 4px solid ${(props) => props.theme.color};
  border-top: 4px solid ${(props) => props.theme.backgroundColor};
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1.5s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
