import Header from "../components/Header/Header";
import { Outlet } from "react-router-dom";
import styled from "@emotion/styled";
import { Toaster } from "react-hot-toast";
import SettingsPopover from "../components/Popover/SettingsPopover";

export default function Layout() {
  return (
    <LayoutWrapper>
      <Header />
      <Main>
        <Outlet />
      </Main>
      <div></div>
      <Wrapper>
        <SettingsPopover />
      </Wrapper>
      <Toaster
        position="bottom-center"
        toastOptions={{
          style: {
            border: `1px solid white`,
            borderRadius: "8px",
            background: "#313131",
            color: "white",
          },
        }}
      />
    </LayoutWrapper>
  );
}

const LayoutWrapper = styled.div`
  min-height: 100vh;
  min-width: 800px;

  @media screen and (max-width: 1300px) {
    padding: var(--spacing-medium);
  }
  padding: var(--spacing-x-large);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  color: ${(props) => props.theme.color};
  background-color: ${(props) => props.theme.backgroundColor};


  transition:
    background-color 0.2s,
    color 0.2s;
`;

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  margin-bottom: var(--spacing-medium);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media screen and (max-height: 700px) {
    gap: 1rem;
  }
`;

const Main = styled.main`
  align-self: center;
`;
