import styled from "@emotion/styled";

export const CapLogo = () => {
  return (
    <StyledSvg
      width="133"
      height="103"
      viewBox="0 0 133 103"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.9 36.5C26.75 36.5 31.8 33.2 33.55 28.25L27.3 24.3C26.3 27.75 23.65 30 19.8 30C14.5 30 10.65 25.3 10.65 18.5C10.65 11.7 14.5 7 19.8 7C24 7 25.95 8.75 27.5 12.55L33.95 9.6C32.45 4.1 27.3 0.499998 19.9 0.499998C9.85 0.499998 3 7.75 3 18.5C3 29.25 9.85 36.5 19.9 36.5ZM60.7676 0.999998V15.55H48.2676V0.999998H40.7676V36H48.2676V21.55H60.7676V36H68.2676V0.999998H60.7676ZM96.6156 36H104.566L92.1156 0.999998H86.0156L73.5656 36H81.2656L83.0656 30H94.8156L96.6156 36ZM84.8156 24L88.9656 10.25L93.0656 24H84.8156ZM130.973 0.999998H104.473V7H113.973V36H121.473V7H130.973V0.999998Z" />
      <path d="M16.96 69H18.496L12.184 52.2H11.032L4.72 69H6.184L7.768 64.584H15.376L16.96 69ZM8.2 63.384L11.584 54L14.944 63.384H8.2ZM39.0829 52.2H35.1709V69H39.0829C44.3149 69 47.2429 65.976 47.2429 60.6C47.2429 55.224 44.3149 52.2 39.0829 52.2ZM39.0349 67.8H36.6109V53.4H39.0349C43.4269 53.4 45.7789 55.968 45.7789 60.6C45.7789 65.232 43.4269 67.8 39.0349 67.8ZM79.9699 69L79.2979 52.2H78.0499L72.2419 65.76L66.4099 52.2H65.1379L64.4659 69H65.8579L66.2899 55.392L71.6659 67.824H72.7219L78.0499 55.488L78.5059 69H79.9699ZM97.9675 69H99.4075V52.2H97.9675V69ZM128.237 52.2V65.832L118.445 52.2H117.533V69H118.853V55.248L128.789 69H129.557V52.2H128.237Z" />
      <path d="M11.056 80.2H5.92V97H7.36V89.416H11.056C14.584 89.416 16.528 87.784 16.528 84.808C16.528 81.856 14.584 80.2 11.056 80.2ZM10.768 88.216H7.36V81.4H10.768C13.696 81.4 15.088 82.504 15.088 84.808C15.088 87.112 13.696 88.216 10.768 88.216Z" />
      <path d="M45.8633 97H47.3993L41.0873 80.2H39.9353L33.6233 97H35.0873L36.6713 92.584H44.2793L45.8633 97ZM37.1033 91.384L40.4873 82L43.8473 91.384H37.1033Z" />
      <path d="M77.5273 80.2V93.832L67.7353 80.2H66.8233V97H68.1433V83.248L78.0793 97H78.8473V80.2H77.5273Z" />
      <path d="M103.742 81.4V80.2H94.6944V97H103.742V95.8H96.1344V89.2H102.926V88H96.1344V81.4H103.742Z" />
      <path d="M121.94 95.8V80.2H120.5V97H129.548V95.8H121.94Z" />
    </StyledSvg>
  );
};

const StyledSvg = styled.svg`
  fill: ${(props) => props.theme.color};
`;
