import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../apiConfig";
import BotCard from "../components/Cards/BotCard";
import Loader from "../components/Loaders/Loader";
import { setBots } from "../features/botsSlice";


export default function Bots() {
  const { bots } = useSelector((state) => state.bots);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => document.title = "Bots List", [])


  useEffect(() => {
    setIsLoading(true);
    // Fetching the bot list
    api
      .get("/bots")
      .then((res) => {
        dispatch(setBots(res.data.body));
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Unable to load bots");
        console.error(err);
        setIsLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    bots.length === 1 && navigate(bots[0].name);
  }, [bots])

  return !isLoading ? (
    <CardWrapper>
      {bots &&
        bots.map((bot, index) => {
          return (
            <BotCard name={bot.name.toUpperCase()} icon={process.env.PUBLIC_URL + '/icons/' + bot.name + '.svg'} key={index} />
          );
        })}
    </CardWrapper>
  ) : (
    <Loader />
  );
}

const CardWrapper = styled.div`
  display: flex;
  max-width: 672px;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media screen and (max-width: 1300px) {
    gap: var(--spacing-large);
  }
  gap: var(--spacing-x-large);
`;
