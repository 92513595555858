import { createSlice } from "@reduxjs/toolkit";

export const backendSettingsSlice = createSlice({
  name: "backendsettings",
  initialState: {
    infos: null,
    inputCharacterLimit: undefined,
    outputCharacterLimit: undefined,
    gptModel: "",
    gptTypes: [],
    gptType: "",
    prompt: "",
    welcomeMessage: "",
    accessPolicy: "",
    context: false,
    sensitiveInfo: false,
  },
  reducers: {
    setInfos: (state, action) => {
      state.infos = action.payload;

      state.inputCharacterLimit = action.payload["inputCharacterLimit"];
      state.outputCharacterLimit = action.payload["outputCharacterLimit"];
      state.gptModel = action.payload["gptModel"];
      state.gptType = action.payload["gptType"];
      state.gptTypes = [
        action.payload["gptType"],
        "Other type",
        "Another type",
      ];
      state.context = action.payload["context"];
      state.sensitiveInfo = action.payload["sensitiveInfo"];
      state.prompt = action.payload["prompt"];
      state.welcomeMessage = action.payload["welcomeMessage"];
      state.accessPolicy = action.payload["accessPolicy"];
    },
    setInputCharacterLimits: (state, action) => {
      state.inputCharacterLimit = action.payload;
      state.infos["inputCharacterLimit"] = action.payload;
    },
    setOutputCharacterLimits: (state, action) => {
      state.outputCharacterLimit = action.payload;
      state.infos["outputCharacterLimit"] = action.payload;
    },
    setGptModel: (state, action) => {
      state.gptModel = action.payload;
      state.infos["gptModel"] = action.payload;
    },
    setSelectedGptType: (state, action) => {
      state.gptType = action.payload;
      state.infos["gptType"] = action.payload;
    },
    setPrompt: (state, action) => {
      state.prompt = action.payload;
      state.infos["prompt"] = action.payload;
    },
    setWelcomeMessage: (state, action) => {
      state.welcomeMessage = action.payload;
      state.infos["welcomeMessage"] = action.payload;
    },
    setAccessPolicy: (state, action) => {
      state.accessPolicy = action.payload;
      state.infos["accessPolicy"] = action.payload;
    },
    setContext: (state, action) => {
      state.context = action.payload;
      state.infos["context"] = action.payload;
    },
    setSensitiveInfo: (state, action) => {
      state.sensitiveInfo = action.payload;
      state.infos["sensitiveInfo"] = action.payload;
    },
    resetInfos: (state) => {
      state.infos = null;
      state.inputCharacterLimit = "";
      state.outputCharacterLimit = "";
      state.gptModel = "";
      state.gptTypes = [];
      state.gptType = "";
      state.prompt = "";
      state.welcomeMessage = "";
      state.accessPolicy = "";
      state.context = "";
      state.sensitiveInfo = "";
    },
  },
});

export const {
  setInfos,
  setInputCharacterLimits,
  setOutputCharacterLimits,
  setGptModel,
  setSelectedGptType,
  setPrompt,
  setAccessPolicy,
  setContext,
  setSensitiveInfo,
  setWelcomeMessage,
  resetInfos,
} = backendSettingsSlice.actions;
export default backendSettingsSlice.reducer;
