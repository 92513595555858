import { createSlice } from "@reduxjs/toolkit";

export const menuSlice = createSlice({
  name: "menu",
  initialState: {
    links: {
      parameters: [],
    },
    currentFilter: null,
  },
  reducers: {
    loadLinks: (state, action) => {
      state.links.parameters = action.payload;
    },
    // Making twice the same function cause this one make the "change" var to true in the changeSlice
    setLinks: (state, action) => {
      state.links.parameters = action.payload;
    },
    addLink: (state, action) => {
      state.links.parameters.push(action.payload);
    },
    removeLink: (state, action) => {
      const index = state.links.parameters.findIndex(
        (link) => link.id === action.payload,
      );
      state.links.parameters.splice(index, 1);
    },
    editLink: (state, action) => {
      const index = state.links.parameters.findIndex(
        (link) => link.id === action.payload.id,
      );
      if (index !== -1) {
        state.links.parameters[index] = action.payload;
      }
    },
    setCurrentFilter: (state, action) => {
      state.currentFilter = action.payload;
    },
  },
});

export const {
  loadLinks,
  setLinks,
  addLink,
  removeLink,
  editLink,
  setCurrentFilter,
} = menuSlice.actions;
export default menuSlice.reducer;
