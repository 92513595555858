import { createSlice } from "@reduxjs/toolkit";
import i18n from "../i18n";

const userLanguage = navigator.language || navigator.userLanguage;
const formattedLanguage = userLanguage.substr(0, 2).toLowerCase();

const languageMapping = {
  en: "en",
  no: "no",
};

const detectedLanguage =
  localStorage.getItem("lang") || languageMapping[formattedLanguage] || "en";

export const langSlice = createSlice({
  name: "lang",
  initialState: {
    lang: detectedLanguage,
    queriesLang: "no",
  },
  reducers: {
    toggleLang: (state) => {
      state.lang = state.lang === "no" ? "en" : "no";
      i18n.changeLanguage(state.lang);
      localStorage.setItem("lang", state.lang);
    },
    toggleQueriesLang: (state) => {
      state.queriesLang = state.queriesLang === "no" ? "en" : "no";
    },
  },
});

export const { toggleLang, toggleQueriesLang } = langSlice.actions;
export default langSlice.reducer;
